import { useState } from "react";

export default (onSubmit) => {
  const [isSubmitting, setSubmitting] = useState(false);
  return [
    isSubmitting,
    async (...args) => {
      if (isSubmitting) return;
      setSubmitting(true);
      if ((await onSubmit(...args)) === false) setSubmitting(false);
    },
  ];
};
